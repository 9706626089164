<template>

<div class="role-edit">
  <div style="margin: 20px 0 0px 20px">
    <MyBreadcrumb></MyBreadcrumb>
  </div>
  <Form :model="formItem" :label-width="80">
    <FormItem :label="$t('system_role_add_name')" align="left">
      <Input v-model="formItem.name" :disabled="type=='check'" style="width: 300px;" :placeholder="$t('system_role_add_name_tip')" ></Input>
    </FormItem>
    <FormItem :label="$t('system_role_add_desc')" align="left">
      <Input v-model="formItem.desc" :disabled="type=='check'" style="width: 300px;" :placeholder="$t('system_role_add_desc_tip')"></Input>
    </FormItem>
  </Form>
  <div class="cont">
    <div class="cont-item">
      <Input v-model="apiName" :disabled="type=='check'" :placeholder="$t('system_role_add_api_name')" @keyup.enter.native="getApiPermissions" @on-blur="getApiPermissions" ></Input>
      <p class="tit">{{ $t('system_role_add_api_list') }}</p>
      <div class="list">
<!--        <div style="border-bottom: 1px solid #e9e9e9;padding-bottom:6px;margin-bottom:6px;">-->
<!--          <Checkbox-->
<!--              :indeterminate="indeterminate"-->
<!--              :value="checkAll"-->
<!--              @click.prevent.native="handleCheckAll"-->
<!--              style="display: block;text-align: left">全选</Checkbox>-->
<!--        </div>-->
        <CheckboxGroup v-model="checkApiAllGroup"  v-if="apiList.length">
          <Checkbox :label="item.name"  :disabled="type=='check'" v-for="item in apiList" :key="item.name" style="display: block;text-align: left;margin-bottom: 10px;">{{item.description}}</Checkbox>
        </CheckboxGroup>
        <div v-else>{{ $t('system_role_add_nodata') }}～</div>
        <Spin size="large" fix v-if="apiLoading"></Spin>
      </div>
    </div>
    <div class="cont-item">
      <Input v-model="menuName" :disabled="type=='check'" :placeholder="$t('system_role_add_menu_name')" @keyup.enter.native="getMenus" @on-blur="getMenus"></Input>
      <p class="tit">{{ $t('system_role_add_menu_list') }}</p>
      <div class="list" >
<!--        <div style="border-bottom: 1px solid #e9e9e9;padding-bottom:6px;margin-bottom:6px;">-->
<!--          <Checkbox-->
<!--              :indeterminate="indeterminate"-->
<!--              :value="checkAll"-->
<!--              @click.prevent.native="handleCheckAll"-->
<!--              style="display: block;text-align: left">全选</Checkbox>-->
<!--        </div>-->
        <CheckboxGroup v-model="checkMenuAllGroup" v-if="menuList.length">
          <Checkbox :label="item.name" :disabled="type=='check'" v-for="item in menuList" :key="item.name" style="display: block;text-align: left;margin-bottom: 10px;">{{item.description}}</Checkbox>
        </CheckboxGroup>
        <div v-else>{{ $t('system_role_add_nodata') }}～</div>
        <Spin size="large" fix v-if="menuLoading"></Spin>
      </div>
    </div>
  </div>
  <div class="bot">
    <Button v-if="type!='check'" type="primary" @click="confirm"  :loading="loading">{{$t('system_role_add_confirm')}}</Button>
    <Button @click="back">{{$t('system_role_add_back')}}</Button>
  </div>
</div>
</template>

<script>
export default {
  name: "roleEdit",
  data(){
    return{
      formItem: {
        name: '',
        desc: '',
      },
      indeterminate: true,
      checkAll: false,
      checkApiAllGroup: [],
      checkMenuAllGroup: [],
      apiList:[],
      menuList:[],
      menuLoading:false,
      apiLoading:false,
      apiName:'',
      menuName:'',
      loading:false,
      oldName:'',
      type:'',
      pageSize:999
    }
  },
  created(){
    this.getApiPermissions();
    this.getMenus();
    if(this.$route.query.name){
      this.oldName = this.$route.query.name;
      this.type = this.$route.query.type;
      this.getInfo();
    }

  },
  methods:{
    getInfo(){
      let data = {
        name:this.oldName
      };
      this.api.system.roleDetail(data).then((res)=>{
        this.formItem = {
          name: res.role.name,
          desc: res.role.description,
        };
        this.checkApiAllGroup = Object.values(res.permissions);
        this.checkMenuAllGroup = Object.values(res.menus);
      })
    },
    handleCheckAll () {
      // if (this.indeterminate) {
      //   this.checkAll = false;
      // } else {
      //   this.checkAll = !this.checkAll;
      // }
      // this.indeterminate = false;
      //
      // if (this.checkAll) {
      //   this.checkAllGroup = ['香蕉', '苹果', '西瓜'];
      // } else {
      //   this.checkAllGroup = [];
      // }
    },
    back(){
      this.$router.go(-1);
    },
    getApiPermissions(){  //获取接口列表
      let data = {
        keyword:this.apiName,
        page:1,
        pageSize:this.pageSize,
      };
      this.apiLoading = true;
      this.api.system.permissions(data).then((res)=>{
        this.apiLoading = false;
        this.apiList = res.list;
      }).catch((err)=>{
        this.apiLoading = false;
      })
    },
    getMenus(){  //获取接口列表
      let data = {
        keyword:this.menuName,
        page:1,
        pageSize:this.pageSize,
      };
      this.menuLoading = true;
      this.api.system.menus(data).then((res)=>{
        this.menuLoading = false;
        this.menuList = res.list;
      }).catch((err)=>{
        this.menuLoading = false;
      })
    },
    confirm(){  //确定
      if(!this.formItem.name){
        this.$Message.warning(this.$t('system_role_add_name_tip'));
        return;
      }
      if(!this.formItem.desc){
        this.$Message.warning(this.$t('system_role_add_desc_tip'));
        return;
      }
      if(!this.checkApiAllGroup.length){
        this.$Message.warning(this.$t('system_role_add_api_list_tip'));
        return;
      }
      if(!this.checkMenuAllGroup.length){
        this.$Message.warning(this.$t('system_role_add_menu_list_tip'));
        return;
      }

      let data = {
        name:this.formItem.name,
        description:this.formItem.desc,
        permissions:this.checkApiAllGroup.join(','),
        menus:this.checkMenuAllGroup.join(',')
      }
      this.loading = true;
      if(!this.oldName){
        this.api.system.roleCreate(data).then((res)=>{
          this.loading = false;
          this.$router.go(-1);
        }).catch((res)=>{
          this.loading = false;
        })
      }else{
        data.name_old = this.oldName;
        this.api.system.roleUpdate(data).then((res)=>{
          this.loading = false;
          this.$router.go(-1);
        }).catch((res)=>{
          this.loading = false;
        })
      }

    }
  }
}
</script>

<style scoped lang="scss">
.role-edit{
  margin-top: 20px;
  .cont{
    display: flex;
    justify-content: flex-start;
    .cont-item{
      padding: 20px;
      flex: 1;
      margin: 0 40px;
      background: #FFFFFF;
      box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
      border-radius: 4px;
      .tit{
        margin-bottom: 20px;
        text-align: left;
        line-height: 48px;
        height: 48px;
        border-bottom: 1px solid #e8eaec;
      }
      .list{
        height: 400px;
        overflow-y: auto;
        position: relative;
      }
    }
  }
  .bot{
    margin-top: 30px;
    margin-bottom: 60px;
    text-align: center;
    >button:nth-child(1){
      margin-right: 20px;
    }
  }
}

</style>
